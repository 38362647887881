import coreScroll from '@nrk/core-scroll'
import { BaseElement } from '../lib/base-element'
import { getElementTag } from '@nrk/dh-server-libs/get-element-tag'

import './components/Etasje/index.module.css'

const tag = getElementTag(__APP_NAME__, __APP_VERSION__)

function safeDefine(elementName, constructor) {
  if (typeof customElements.get(elementName) === 'undefined') {
    customElements.define(elementName, constructor)
  }
}

export class AppElement extends BaseElement {
  name = tag

  render() {
    this.initCoreScrollScroll()
    this.initEventTracking()
    this.extraTrackerSetup()
  }

  initCoreScrollScroll() {
    coreScroll('#langlesingscroll')
  }

  initEventTracking = () => {
    this.addEventListener('langlesingscroll.click', (evt) => {
      const direction = evt['detail'].move
      this.sendGaEvent({ eventAction: `click button ${direction}` })
    })
  }

  get gaCategory() {
    return 'dh-modul-Dh Langlesing-front'
  }

  async extraTrackerSetup() {
    const tracker = await this.coreAnalyticsTracker

    tracker('recommendations', {
      contentId: 'kur:12',
    })
  }
}

safeDefine(tag, AppElement)
