const { getContextId } = require('@nrk/dh-server-libs/src/get-context-id')

let _coreAnalyticsPromise = undefined
function getCoreAnalytics() {
  if (!_coreAnalyticsPromise) {
    if ('coreAnalytics' in window) {
      _coreAnalyticsPromise = Promise.resolve(window['coreAnalytics'])
    } else {
      _coreAnalyticsPromise = import('@nrk/core-analytics' /* webpackChunkName: "ca" */).then(
        (m) => m.default
      )
    }
  }
  return _coreAnalyticsPromise
}

/**
 * Custom implementation of '@nrk/dh-server-libs/base-element'
 *
 * - Removes sendError/dh-catch
 * - Adds support for existing ga categories
 *
 */
class BaseElement extends HTMLElement {
  constructor() {
    super()
    this.sendGaEvent = this.sendGaEvent.bind(this)
  }
  connectedCallback() {
    if (!this.name) throw Error('Name not provided')
    this.setupTracker()
    if (typeof this.render === 'function') this.render()
  }

  setupTracker() {
    this.coreAnalyticsTracker = new Promise((resolve) => {
      getCoreAnalytics().then((coreAnalytics) => {
        const tracker = coreAnalytics.create()

        tracker('initialize', {
          container: this,
          parentId: this.metadata.parentId || getContextId(window.location.href),
          ga: this.dataset.gaPropertyId,
        })

        tracker('context', {
          title: this.metadata.title,
          contentId: this.metadata.selfId,
          publishDate: this.metadata.published,
        })

        resolve(tracker)
      })
    })
  }

  async sendGaEvent(event) {
    const tracker = await this.coreAnalyticsTracker
    tracker('event', {
      ga: {
        eventCategory: this.gaCategory,
        ...event,
      },
    })
  }

  get metadata() {
    return this.dataset.metadata ? JSON.parse(this.dataset.metadata) : {}
  }

  get preloadedState() {
    return this.dataset.preloadedState ? JSON.parse(this.dataset.preloadedState) : null
  }

  get gaCategory() {
    return 'dh-modul-Dh Langlesing'
  }
}

module.exports.BaseElement = BaseElement
